// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-applikacio-fejlesztes-tsx": () => import("./../../../src/pages/applikacio-fejlesztes.tsx" /* webpackChunkName: "component---src-pages-applikacio-fejlesztes-tsx" */),
  "component---src-pages-arcedula-tsx": () => import("./../../../src/pages/arcedula.tsx" /* webpackChunkName: "component---src-pages-arcedula-tsx" */),
  "component---src-pages-asztalra-tsx": () => import("./../../../src/pages/asztalra.tsx" /* webpackChunkName: "component---src-pages-asztalra-tsx" */),
  "component---src-pages-bemutatkozas-tsx": () => import("./../../../src/pages/bemutatkozas.tsx" /* webpackChunkName: "component---src-pages-bemutatkozas-tsx" */),
  "component---src-pages-budapestcomiccon-tsx": () => import("./../../../src/pages/budapestcomiccon.tsx" /* webpackChunkName: "component---src-pages-budapestcomiccon-tsx" */),
  "component---src-pages-egyedi-fejlesztesek-tsx": () => import("./../../../src/pages/egyedi-fejlesztesek.tsx" /* webpackChunkName: "component---src-pages-egyedi-fejlesztesek-tsx" */),
  "component---src-pages-egyedi-webshop-dizajn-tsx": () => import("./../../../src/pages/egyedi-webshop-dizajn.tsx" /* webpackChunkName: "component---src-pages-egyedi-webshop-dizajn-tsx" */),
  "component---src-pages-elitsafety-tsx": () => import("./../../../src/pages/elitsafety.tsx" /* webpackChunkName: "component---src-pages-elitsafety-tsx" */),
  "component---src-pages-eventure-tsx": () => import("./../../../src/pages/eventure.tsx" /* webpackChunkName: "component---src-pages-eventure-tsx" */),
  "component---src-pages-festivaltravel-tsx": () => import("./../../../src/pages/festivaltravel.tsx" /* webpackChunkName: "component---src-pages-festivaltravel-tsx" */),
  "component---src-pages-gsm-centrum-tsx": () => import("./../../../src/pages/gsm-centrum.tsx" /* webpackChunkName: "component---src-pages-gsm-centrum-tsx" */),
  "component---src-pages-hibafeltaras-tsx": () => import("./../../../src/pages/hibafeltaras.tsx" /* webpackChunkName: "component---src-pages-hibafeltaras-tsx" */),
  "component---src-pages-hirek-tsx": () => import("./../../../src/pages/hirek.tsx" /* webpackChunkName: "component---src-pages-hirek-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kapcsolat-tsx": () => import("./../../../src/pages/kapcsolat.tsx" /* webpackChunkName: "component---src-pages-kapcsolat-tsx" */),
  "component---src-pages-netlampa-tsx": () => import("./../../../src/pages/netlampa.tsx" /* webpackChunkName: "component---src-pages-netlampa-tsx" */),
  "component---src-pages-office-depot-tsx": () => import("./../../../src/pages/office-depot.tsx" /* webpackChunkName: "component---src-pages-office-depot-tsx" */),
  "component---src-pages-play-it-store-tsx": () => import("./../../../src/pages/play-it-store.tsx" /* webpackChunkName: "component---src-pages-play-it-store-tsx" */),
  "component---src-pages-play-it-tsx": () => import("./../../../src/pages/play-it.tsx" /* webpackChunkName: "component---src-pages-play-it-tsx" */),
  "component---src-pages-power-life-tsx": () => import("./../../../src/pages/power-life.tsx" /* webpackChunkName: "component---src-pages-power-life-tsx" */),
  "component---src-pages-seo-audit-tsx": () => import("./../../../src/pages/seo-audit.tsx" /* webpackChunkName: "component---src-pages-seo-audit-tsx" */),
  "component---src-pages-sikeres-kapcsolatfelvetel-tsx": () => import("./../../../src/pages/sikeres-kapcsolatfelvetel.tsx" /* webpackChunkName: "component---src-pages-sikeres-kapcsolatfelvetel-tsx" */),
  "component---src-pages-snowattack-tsx": () => import("./../../../src/pages/snowattack.tsx" /* webpackChunkName: "component---src-pages-snowattack-tsx" */),
  "component---src-pages-szolgaltatasok-tsx": () => import("./../../../src/pages/szolgaltatasok.tsx" /* webpackChunkName: "component---src-pages-szolgaltatasok-tsx" */),
  "component---src-pages-videojatek-bolt-tsx": () => import("./../../../src/pages/videojatek-bolt.tsx" /* webpackChunkName: "component---src-pages-videojatek-bolt-tsx" */),
  "component---src-pages-webshop-berles-tsx": () => import("./../../../src/pages/webshop-berles.tsx" /* webpackChunkName: "component---src-pages-webshop-berles-tsx" */),
  "component---src-pages-webshop-funkcioi-tsx": () => import("./../../../src/pages/webshop-funkcioi.tsx" /* webpackChunkName: "component---src-pages-webshop-funkcioi-tsx" */),
  "component---src-pages-webshop-uzemeltetes-tsx": () => import("./../../../src/pages/webshop-uzemeltetes.tsx" /* webpackChunkName: "component---src-pages-webshop-uzemeltetes-tsx" */)
}

